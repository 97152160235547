.nvsbanner{background: linear-gradient(to right, #006bc0 , #691dc0);}
.nvs_banner_container{padding: 130px 0 80px 100px;;}
.nvs_banner_container h1{color: #FFF;font-weight: 900;font-size: 36px;}
.bigspacing {padding: 80px 0;}
.smallspacing {padding: 40px 0;}
.nvfooter{
  ul{
    padding: 0;
    margin: 0;

    li{
      list-style: none;
    }
  }
} 

.wid900{width: 900px;margin: 0 auto;}
.ttbg{background-color: #faf9fc;}
.ppbg {background-color: #f5f4fa;}
.tncbox{
  ol{
    counter-reset: item;padding: 0;
    
    li{
      display: block;
      position: relative;
      font-family: 'Gilroy Medium';
      margin-bottom: 40px;

      h3{
        font-size: 24px;
        margin-left: 30px;
        margin-bottom: 15px;
        font-weight: 700;
      }

      h4{
        font-weight: 500;
      }

      h5{
        font-weight: 500;
        margin-bottom: 15px;
      }

      ol{

        li{
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
          text-align: justify;
          font-family: 'Gilroy Light';
          margin-left: 50px;
          font-size: 16px;
          margin-bottom: 15px;

          ul{
            li{
              list-style: disc;
              margin-left: 20px;
              margin-top: 10px;
            }
          } 
        } 

        li:before{
          margin-left: -50px;
          font-family: 'Gilroy Light';
          font-size: 16px;
          top:3px;
        }
      } 

    }

    li:before {
      content: counters(item, ".") ".";
      counter-increment: item;
      position: absolute;
      left: 0;
      font-family: 'Gilroy Medium';
      font-size: 24px;
      top: -2px;
    }
  }

  ul{

    li:before{display: none;}

  } 
}

.tncbox ol 

.termsAndCondition__list{
  li{
    font-family: 'Gilroy Light';
  }
  li:before{
    font-size: 24px;
    line-height: 1.2;
    top: 0;
  }
}

@media screen and (max-width:768px){
  .wid900{width: 100%;}
  .tncbox {padding-bottom:40px !important;}
  .termsAndCondition__list li {padding-left: 0 !important; }
  .termsAndCondition__list li h3 {padding-left: 40px !important; }
  .termsAndCondition__list ol li {padding: 0;}
}

.navbar{background: transparent;padding: 0;height:100px;position: fixed;top: 0;left: 0;right: 0;z-index: 999999;-webkit-transition: background 150ms cubic-bezier(.645,.045,.355,1);-o-transition: background 150ms cubic-bezier(.645,.045,.355,1);transition: background 150ms cubic-bezier(.645,.045,.355,1);}
.navbar .navbar-brand{height: 100px;line-height: 100px;padding: 20px 0;}
.navbar .nl_logo,.hidebig .nl_logo{background: url(../Image/novus_loyalty_logo_white.png) no-repeat;display: inline-block;width: 144px;height: 60px;}
.hidebig .nl_logo{margin: 10px 0 0 10px;}
.navbar.sticky{top:0;}
.navbar.sticky .nl_logo,.navbar.white .nl_logo{background: url(../Image/novus_loyalty_logo.png) no-repeat;display: inline-block;width: 144px;height: 60px;}
.navbar.sticky, .navbar.white{background: #fff;-webkit-box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);}
.navbar-menu{position: relative;float: right;}
.navbar-menu .navigation_box ul{margin-bottom: 0;}
.navigation_box ul li{display: inline-block;position: relative;}
.navigation_box ul li a{padding: 0 15px;color: #FFF;display: block;position: relative;}
.navbar.sticky .navigation_box ul li a,.navbar.white .navigation_box ul li a{color:#0a6ebe}
.navigation_box a.bookdemo{background: #ff9900;padding: 10px 20px;border-radius: 30px;border: 1px solid #ff9900;text-transform: uppercase;font-weight: 500;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;margin-right:15px;}
.navigation_box a.tryforfreebtn{background: transparent;padding: 10px 20px;border-radius: 30px;border: 1px solid #FFF;text-transform: uppercase;font-weight: 500;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.navigation_box a.bookdemo:hover{background: #ec8f04;}
.navbar.sticky .navigation_box a.bookdemo,.navbar.white .navigation_box a.bookdemo{color:#FFF;background: #0a6ebe;border-color: #0a6ebe;animation: fade-shadow 6s infinite;}
.navbar.sticky .navigation_box a.tryforfreebtn,.navbar.white .navigation_box a.tryforfreebtn{border-color: #0a6ebe;}
.arrow{border: solid #fbfaff;border-width: 0 1px 1px 0;display: inline-block;padding: 3px;}
.down{transform: rotate(45deg);-webkit-transform: rotate(45deg);margin: 0 0 3px 3px;transition: all 0.5s ease-in-out;}
.right{transform: rotate(-455deg);-webkit-transform: rotate(-45deg);margin: 0 0 3px 3px;transition: all 0.5s ease-in-out;}
.navbar.sticky .navigation_box ul li a i.arrow,.navbar.white .navigation_box ul li a i.arrow{border-color: #0a6ebe;}
.navigation_box ul li .customDropDown{position: absolute;top: 100%;left: 0;padding: 0;width: 240px;text-align: left;margin-top: 40px;opacity: 0;visibility: hidden;-webkit-transform: translateY(100px);-ms-transform: translateY(100px);-o-transform: translateY(100px);transform: translateY(100px);-webkit-transition: all 650ms cubic-bezier(.76,0,.11,1);-o-transition: all 650ms cubic-bezier(.76,0,.11,1);transition: all 650ms cubic-bezier(.76,0,.11,1);-webkit-transition-timing-function: cubic-bezier(.76,0,.31,1);-o-transition-timing-function: cubic-bezier(.76,0,.31,1);transition-timing-function: cubic-bezier(.76,0,.31,1);box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;border-radius: 10px;}
.navigation_box ul li:hover .customDropDown{opacity: 1;visibility: visible;-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);transform: translateY(0);}
.navigation_box ul li:hover a i.arrow.down{transform: rotate(225deg);}
.navigation_box ul li .customDropDown li{display: block;font-size: 13px;padding: 0 15px;line-height: 20px;background: #FFF;}
.navigation_box ul li .customDropDown li a{font-family: 'Gilroy Medium';color: #000;padding: 15px;border-bottom: 1px solid rgba(0, 0, 0, 0.5);font-size: 14px;}
.navigation_box ul li .customDropDown li:first-child{border-top-left-radius: 10px;border-top-right-radius: 10px;}
.navigation_box ul li .customDropDown li:last-child{border-bottom: none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;}
.navigation_box ul li .customDropDown li:last-child a{border-bottom: none;}
.navbar.sticky .navigation_box ul li .customDropDown li a,.navbar.white .navigation_box ul li .customDropDown li a{color:#000;}
@keyframes fade-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(244 244 244);
    box-shadow: 0 0 0 0 rgb(244 244 244);
  }
  10%{
    -webkit-box-shadow: 0 0 0 0 rgb(244 244 244);
    box-shadow: 0 0 0 0 rgb(244 244 244);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgb(244 244 244);
    box-shadow: 0 0 0 10px rgb(244 244 244);
  }
}
.navigation_box ul li .customDropDown li .customDropDownSub{position: absolute;top: 0;left: 100%;padding: 0;width: 240px;text-align: left;opacity: 0;visibility: hidden;-webkit-transform: translateY(100px);-ms-transform: translateY(100px);-o-transform: translateY(100px);transform: translateY(100px);-webkit-transition: all 650ms cubic-bezier(.76,0,.11,1);-o-transition: all 650ms cubic-bezier(.76,0,.11,1);transition: all 650ms cubic-bezier(.76,0,.11,1);-webkit-transition-timing-function: cubic-bezier(.76,0,.31,1);-o-transition-timing-function: cubic-bezier(.76,0,.31,1);transition-timing-function: cubic-bezier(.76,0,.31,1);margin-left: 3px;box-shadow: rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px;border-radius: 10px;}
.navigation_box ul li .customDropDown li:hover .customDropDownSub{opacity: 1;visibility: visible;-webkit-transform: translateY(0);-ms-transform: translateY(0);-o-transform: translateY(0);transform: translateY(0);}
.customDropDown li a i.arrow{border-color: #000;float: right;margin-top: 7px;border-width: 0 2px 2px 0;}
.navigation_box ul li .customDropDown li:hover{background: #006bc0;}
.navigation_box ul li .customDropDown li:hover a{color: #FFF;}
.navigation_box ul li .customDropDown li:hover a i.arrow{border-color: #FFF;}
.navigation_box ul li .customDropDown li:hover .customDropDownSub li a{color: #000;}
.navigation_box ul li .customDropDown li:hover .customDropDownSub li:hover a{color: #fff;}
.navbar.sticky .navigation_box ul li .customDropDown li:hover,.navbar.white .navigation_box ul li .customDropDown li:hover{background: #006bc0;}
.navbar.sticky .navigation_box ul li .customDropDown li:hover a,.navbar.white .navigation_box ul li .customDropDown li:hover a{color: #FFF;}
.navbar.sticky .navigation_box ul li .customDropDown li:hover .customDropDownSub li a,.navbar.white .navigation_box ul li .customDropDown li:hover .customDropDownSub li a{color: #000;}
.navbar.sticky .navigation_box ul li .customDropDown li:hover .customDropDownSub li:hover a,.navbar.white .navigation_box ul li .customDropDown li:hover .customDropDownSub li:hover a{color: #fff;}
.header_space{margin-top: 100px;}
.header_logo{position: relative;z-index: 1;float: left;padding: 4px 0;}
.header_logo a{display: block;width: 150px;height: 80px;outline: 0;background: url(../Image/novus_loyalty_logo.png) no-repeat;background-size: 100%;margin-top: 15px;}
.navigation_links{height: 100px;line-height: 100px;}
.navigation_links ul{margin-left: 100px;float: right;}
.navigation_links ul li{display: inline-block;}
.navigation_links ul li a{color: #000;text-transform: uppercase;padding: 15px 15px;}
.navigation_links ul li a.client_login{padding: 10px 30px;border: 1px solid #006bc0;border-radius: 30px;}
.navigation{-webkit-transition: all 0.5s ease;-moz-transition: position 10s;-ms-transition: position 10s;-o-transition: position 10s;transition: all 0.5s ease;}
.navigation_logo{width: 100px;margin: 15px 15px 10px;}
div.bookdemomobbox{display: none;}

@media only screen and (max-width:768px){
  div.bookdemomobbox{display: block;}
  div.bookdemomobbox ul li{display: inline-block;}
  div.bookdemomobbox ul li.menuBtn{display: inline-block;}
  div.bookdemomobbox .menuBtn{display: inline-block;}
  div.bookdemomobbox .menuBtn a{position: relative;top: 7px;}
}

/*** Footer ***/

.nlgradbg {
  background: linear-gradient(to right,#006bc0,#691dc0)
}

.novus_footer{padding: 40px 0;}
.novus_footer .footer_link_box{padding: 0 0 0 60px;}
.novus_footer .footer_link_box ul li{margin-bottom: 15px;text-transform: uppercase;}
.novus_footer .footer_link_box ul li:nth-last-child(1){margin-bottom: 0;}
.novus_footer .footer_link_box ul li a{color: #000;}

.footer_row{margin-top: 60px;}
.footer_row h5{margin-bottom: 20px;font-weight: 700;color: #FFF}
.footer_row ul li{margin: 10px 0;font-size: 16px;}
.footer_row ul li a{color: #FFF;}
.footer_header{display: none;}

.footer_copyright{padding: 10px 0;}
.footer_copyright p{margin-bottom: 0;color: #ffffff;font-family: 'Gilroy Light';}
.footer_copyright ul li{display: inline-block;margin-right: 30px;color: #fff;}
.footer_copyright ul li:nth-child(5){margin-right: 0;}
.footer_copyright ul li a{color: #fff;}

.footer_copyright_links{float: right;}
// .linkedin_{background: url(../Image/linkedin.png) no-repeat;background-size:contain;width: 38px;height: 38px;display: inline-block;position: absolute;margin-top: 20px;}
.pci_dss{background: url(../Image/pci-dss.png) no-repeat;width: 78px;height: 38px;display: inline-block;margin-right: 10px;}
.linkedinfollow{position:relative;display: inline-block;margin-bottom: 20px;}
.showonmobonly {display: none;}

/*** New Footer ***/

@media only screen and (min-width:769px){
  .nffooterlinks,.nfcopyright{display: none;}
  }
  
  @media only screen and (max-width:768px){
    .bigspacing {padding: 40px 0;}
    .hideonnewfooter{display: none;}
    .nfnewsletter{margin-top: 0;}
    .nfnewsletter h6{text-align: center;font-size: 18px;}
    .nfnewsletter .newsletter_form input{width: 100%;padding: 15px 20px;}
    .nfnewsletter .newsletter_form button{margin-top: -44px;margin-right: 10px;}
    .nffooterlinks{margin-top: 20px;margin-bottom: 15px;}
    .nffooterlinks ul{text-align: center;}
    .nffooterlinks ul li{display: inline-block;margin-right: 20px;margin-bottom: 5px;}
    .nffooterlinks ul li a{color: #FFF;font-size: 1.32rem;}
    .nfcopyright{text-align: center;margin-top: 20px;}
    .nfcopyright ul li{display: inline-block;margin-right: 20px;font-size: 1.5rem;color: #FFF;}
    .navbar-menu ul li{display: none;}
    .navbar-menu ul li.menuBtn{display: block;}
    .nvsbanner{height: auto;background-position: center;margin-top: 0;padding-top: 100px;}
    .nvs_banner_container{padding: 40px 20px;}
    .nvs_banner_container h1{font-size: 28px;}
    .footer_link_list, .boxwithcircle:before, .boxwithcirclereverse:after {display: none;}
    .footer_row.row-cols-1>* {width: 50%;padding-left: 20px;}
    .footer_row h5 {display: none;}
    .footer_row h5.footer_header {display: block;font-size: 1.6rem;}
    .hideonmob,.footer_logo_hide_on_mob {display: none;}
    .footer_row {margin-top: 20px;}
    .showonmobonly {display: inline-block;}
    .navbar .nl_logo,.navbar.sticky .nl_logo, .navbar.white .nl_logo{width: 120px;height: 50px;background-size: contain;}
  }

/*** Menu Wrapper ***/

@media (min-width: 769px) {
  .menuWrapper{position: fixed;top: 0;left: 0;right: 0;bottom: 0;opacity: 0;z-index: 2;overflow-y: scroll;overflow-x: hidden;visibility: hidden;}
  //.menuWrapper .menuInner{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 1;opacity: 0;visibility: hidden;background: url(../img/menu_bg.webp) left top no-repeat #012a5e;background-size: cover;-webkit-transition: all 50ms ease-out;-o-transition: all 50ms ease-out;transition: all 50ms ease-out;-webkit-transition-delay: 0s;-o-transition-delay: 0s;transition-delay: 0s;}
  .menuWrapper .circle{content: "";position: fixed;top: 0;right: 0;width: 60%;z-index: -1;height: 100%;background: rgba(1, 42, 94, 0.88);-webkit-transition: all 50ms cubic-bezier(0.165, 0.84, 0.44, 1);-o-transition: all 50ms cubic-bezier(0.165, 0.84, 0.44, 1);transition: all 50ms cubic-bezier(0.165, 0.84, 0.44, 1);}
}
@media (min-width: 769px) and (max-width: 1024px) {
  .menuWrapper .circle{width: 100%;}
  .nvsbofferingheadingbox,.nvsblorow{padding: 0 30px;}
  .nvsbofferingbox{height: auto;}
}
@media (min-width: 769px) {
  .menuWrapper.showMenu{opacity: 1;visibility: visible;}
  .menuWrapper.showMenu .menuInner{opacity: 1;visibility: visible;-webkit-transition: all 0.8s ease-out;-o-transition: all 0.8s ease-out;transition: all 0.8s ease-out;}
  .menuWrapper .navContainer{position: absolute;top: 50%;left: 45%;width: 400px;margin-top: -75px;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);-o-transform: translateY(-50%);transform: translateY(-50%);}
  .menuWrapper .navContainer ul{margin: 0;}
  .menuWrapper .navContainer ul li{position: relative;z-index: 1;display: block;font-weight: 500;font-size: 40px;line-height: 60px;-webkit-transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);}
  .menuWrapper .navContainer ul li a{display: inline-block;color: #fff;-webkit-transition: color 0.6s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: color 0.6s cubic-bezier(0.4, 0, 0.2, 1);transition: color 0.6s cubic-bezier(0.4, 0, 0.2, 1);}
}
@media (min-width: 769px) and (max-width: 1366px) {
  .menuWrapper .navContainer ul li a{padding-right: 0;}
}
@media (min-width: 769px) {
  .menuWrapper .navContainer ul li.hasDropDown:before{content: "";position: absolute;top: 33px;left: 340px;width: 0;height: 1px;z-index: -1;background: #fff;-webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);}
}
@media (min-width: 769px) and (max-width: 1199px) {
  .menuWrapper .navContainer ul li.hasDropDown:before{top: 27px;left: 280px;}
}
@media (min-width: 769px) {
  .menuWrapper .navContainer ul li .dropdown{position: absolute;top: 21px;left: 100%;padding-left: 0;visibility: hidden;-webkit-transition: visibility 10ms ease-out;-o-transition: visibility 10ms ease-out;transition: visibility 10ms ease-out;-webkit-transition-delay: 0.8s;-o-transition-delay: 0.8s;transition-delay: 0.8s;}
  .menuWrapper .navContainer ul li .dropdown.adjustUpSide{top: -75px;}
  .menuWrapper .navContainer ul li .dropdown.adjustUpSide2{top: auto;bottom: 0;}
  .menuWrapper .navContainer ul li .dropdown li{font-weight: 500;font-size: 16px;opacity: 0;margin: 0 0 20px;text-align: left;line-height: 24px;white-space: nowrap;text-transform: none;-webkit-transform: translateX(-30%);-ms-transform: translateX(-30%);-o-transform: translateX(-30%);transform: translateX(-30%);-webkit-transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1), transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1), transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1), transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);}
  .menuWrapper .navContainer ul li .dropdown li:before{display: none;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(1){-webkit-transition-delay: 780ms;-o-transition-delay: 780ms;transition-delay: 780ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(2){-webkit-transition-delay: 740ms;-o-transition-delay: 740ms;transition-delay: 740ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(3){-webkit-transition-delay: 0.7s;-o-transition-delay: 0.7s;transition-delay: 0.7s;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(4){-webkit-transition-delay: 660ms;-o-transition-delay: 660ms;transition-delay: 660ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(5){-webkit-transition-delay: 620ms;-o-transition-delay: 620ms;transition-delay: 620ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(6){-webkit-transition-delay: 540ms;-o-transition-delay: 540ms;transition-delay: 540ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(7){-webkit-transition-delay: 520ms;-o-transition-delay: 520ms;transition-delay: 520ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(8){-webkit-transition-delay: 480ms;-o-transition-delay: 480ms;transition-delay: 480ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(9){-webkit-transition-delay: 440ms;-o-transition-delay: 440ms;transition-delay: 440ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(10){-webkit-transition-delay: 0.4s;-o-transition-delay: 0.4s;transition-delay: 0.4s;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(11){-webkit-transition-delay: 360ms;-o-transition-delay: 360ms;transition-delay: 360ms;}
  .menuWrapper .navContainer ul li .dropdown li:nth-child(12){-webkit-transition-delay: 320ms;-o-transition-delay: 320ms;transition-delay: 320ms;}
  .menuWrapper .navContainer ul li .dropdown li a{background: 0 0;}
}
@media (min-width: 769px) and (max-width: 1366px) {
  .menuWrapper .navContainer ul li .dropdown li{font-size: 15px;}
}
@media (min-width: 769px) and (max-width: 1199px) {
  .menuWrapper .navContainer ul li .dropdown{top: 15px;}
}
@media (min-width: 769px){
  .menuWrapper .navContainer ul li:hover.hasDropDown:before{width: 40px;}
  .menuWrapper .navContainer ul li:hover a{color: #fff;}
  .menuWrapper .navContainer ul li:hover .dropdown{visibility: visible;-webkit-transition-delay: 10ms;-o-transition-delay: 10ms;transition-delay: 10ms;}
  .menuWrapper .navContainer ul li:hover .dropdown li{opacity: 1;-webkit-transform: translateX(0);-ms-transform: translateX(0);-o-transform: translateX(0);transform: translateX(0);}
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(1) {
      -webkit-transition-delay: 340ms;
      -o-transition-delay: 340ms;
      transition-delay: 340ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(2) {
      -webkit-transition-delay: 380ms;
      -o-transition-delay: 380ms;
      transition-delay: 380ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(3) {
      -webkit-transition-delay: 420ms;
      -o-transition-delay: 420ms;
      transition-delay: 420ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(4) {
      -webkit-transition-delay: 460ms;
      -o-transition-delay: 460ms;
      transition-delay: 460ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(5) {
      -webkit-transition-delay: 0.5s;
      -o-transition-delay: 0.5s;
      transition-delay: 0.5s;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(6) {
      -webkit-transition-delay: 540ms;
      -o-transition-delay: 540ms;
      transition-delay: 540ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(7) {
      -webkit-transition-delay: 580ms;
      -o-transition-delay: 580ms;
      transition-delay: 580ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(8) {
      -webkit-transition-delay: 620ms;
      -o-transition-delay: 620ms;
      transition-delay: 620ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(9) {
      -webkit-transition-delay: 660ms;
      -o-transition-delay: 660ms;
      transition-delay: 660ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(10) {
      -webkit-transition-delay: 0.7s;
      -o-transition-delay: 0.7s;
      transition-delay: 0.7s;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(11) {
      -webkit-transition-delay: 740ms;
      -o-transition-delay: 740ms;
      transition-delay: 740ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown li:nth-child(12) {
      -webkit-transition-delay: 780ms;
      -o-transition-delay: 780ms;
      transition-delay: 780ms;
  }
  .menuWrapper .navContainer ul li:hover .dropdown:hover li a{opacity: 0.6;}
  .menuWrapper .navContainer ul li:hover .dropdown:hover li a:hover{opacity: 1;}
}
@media (min-width: 769px) and (max-width: 1199px) {
  .menuWrapper .navContainer ul li{font-size: 33px;line-height: 60px;}
}
@media (min-width: 769px) {
  .menuWrapper .navContainer ul:hover li.hasDropDown{opacity: 0.6;}
  .menuWrapper .navContainer ul:hover li.hasDropDown:hover{opacity: 1;}
}
@media (min-width: 769px) and (max-width: 1199px) {
  .menuWrapper .navContainer{width: 350px;}
  .blcontained{width: 100%;margin: 0 auto;}
}
@media (min-width: 769px) and (max-width: 1024px) {
  .menuWrapper .navContainer{left: 80px;}
}
@media (max-width: 768px) {
  .menuWrapper {position: fixed;top: 0;left: 0;bottom: 0;width: 100%;z-index: 99;visibility: hidden;-webkit-transition: visibility 850ms ease-out;-o-transition: visibility 850ms ease-out;transition: visibility 850ms ease-out;}
  .menuWrapper:before {position: absolute;content: "";top: 0;left: 0;right: 0;bottom: 0;z-index: 1;background: #000;-webkit-transform: scaleX(0);-ms-transform: scaleX(0);-o-transform: scaleX(0);transform: scaleX(0);-webkit-transform-origin: center left;-ms-transform-origin: center left;-o-transform-origin: center left;transform-origin: center left;-webkit-transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);-webkit-transition-delay: 250ms;-o-transition-delay: 250ms;transition-delay: 250ms;display: none;}
  .menuWrapper:after{position: absolute;content: "";top: 0;left: 0;width: 96%;z-index: 3;bottom: 0;background: rgba(13, 67, 136, 0.96);-webkit-transform: scaleX(0);-ms-transform: scaleX(0);-o-transform: scaleX(0);transform: scaleX(0);-webkit-transform-origin: center left;-ms-transform-origin: center left;-o-transform-origin: center left;transform-origin: center left;-webkit-transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);-webkit-transition-delay: 0.1s;-o-transition-delay: 0.1s;transition-delay: 0.1s;}
  .menuWrapper .menuInner {position: relative;z-index: 100;height: 100%;}
  .menuWrapper .menuInner .navContainer{height: 100%;overflow: auto;padding: 150px 0 60px;-webkit-transform: translateX(-100%);-ms-transform: translateX(-100%);-o-transform: translateX(-100%);transform: translateX(-100%);-webkit-transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);-webkit-transition-delay: 0.4s;-o-transition-delay: 0.4s;transition-delay: 0.4s;}
  .menuWrapper .menuInner .navContainer ul{padding: 0;}
  .menuWrapper .menuInner .navContainer ul li{display: block;font-size: 30px;line-height: 60px;}
  .menuWrapper .menuInner .navContainer ul li a{display: inline-block;color: #fff;padding: 0 30px;}
  .menuWrapper .menuInner .navContainer ul li .dropdown{display: none;padding-left: 15px;}
  .menuWrapper .menuInner .navContainer ul li .dropdown li{font-size: 16px;opacity: 0.8;line-height: 30px;}
  .menuWrapper .menuInner .navContainer ul li.active .dropdown{display: block;}
  .menuWrapper .menuInner .addressBox{padding: 0;opacity: 0;-webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);-webkit-transition-delay: 0.6s;-o-transition-delay: 0.6s;transition-delay: 0.6s;}
  .menuWrapper .menuInner .addressBox .atext,.menuWrapper .menuInner .addressBox .rightBox{display: none;}
  .menuWrapper .menuInner .addressBox .follow{padding: 11px 30px;}
  .menuWrapper .menuInner .addressBox .follow h4{float: left;margin: 0;width: 50%;font-family: proxima_novaregular;color: #fff;line-height: 28px;letter-spacing: 1px;text-transform: uppercase;}
  .menuWrapper .menuInner .addressBox .follow ul{padding: 0;margin: 0;width: 50%;float: right;text-align: right;}
  .menuWrapper .menuInner .addressBox .follow ul li{display: inline-block;font-size: 18px;margin: 0 10px;line-height: 28px;}
  .menuWrapper .menuInner .addressBox .follow ul li a{color: #fff;display: block;}
  .nav-active .menuWrapper{opacity: 1;visibility: visible;transition-delay: 0s;}
  .nav-active .menuWrapper:before {-webkit-transform: scaleX(1);-ms-transform: scaleX(1);transform: scaleX(1);transition-delay: 0.2s;}
  .nav-active .menuWrapper:after{-webkit-transform: scaleX(1);-ms-transform: scaleX(1);transform: scaleX(1);transition-delay: 0.3s;}
  .nav-active .menuWrapper .addressBox,.nav-active .menuWrapper .navContainer{opacity: 1;-webkit-transform: translateX(0);-ms-transform: translateX(0);-o-transform: translateX(0);transform: translateX(0);}
}

.navbar .menuBtn{float: none !important;margin: 0 0 0 10px;display: none;}
@media (max-width: 480px) {
  .navbar .menuBtn {margin: 0 10px;}
}

@media (max-width: 360px){
  .navbar .menuBtn {
      margin: 0 5px;
  }
}
.navbar .menuBtn a{position: relative;width: 33px;height: 26px;cursor: pointer;display: block;z-index: 999999999999;-webkit-transition: all 20ms cubic-bezier(0.4, 0, 0.2, 1);-o-transition: all 20ms cubic-bezier(0.4, 0, 0.2, 1);transition: all 20ms cubic-bezier(0.4, 0, 0.2, 1);-webkit-transition-delay: 0.1s;-o-transition-delay: 0.1s;transition-delay: 0.1s;}
.navbar .menuBtn span{position: absolute;top: 0;left: 0;display: block;width: 33px;height: 3px;z-index: 1;background: #fff;-webkit-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);-o-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);}
.navbar .menuBtn.new_btn span{background-color: #FFF;}
.navbar .menuBtn span:last-child{top: 22px;}
.navbar .menuBtn span.cross{top: 10px;left: 7px;z-index: 2;background: 0 0;}
.navbar .menuBtn span.cross:after,.navbar .menuBtn span.cross:before {display: block;content: "";position: absolute;top: 50%;left: 0;width: 100%;height: 3px;background: #fff;-webkit-transition: all 350ms cubic-bezier(0.4, 0, 0.2, 1);-o-transition: all 350ms cubic-bezier(0.4, 0, 0.2, 1);transition: all 350ms cubic-bezier(0.4, 0, 0.2, 1);}
.navbar .menuBtn.new_btn span.cross:after, .navbar .menuBtn.new_btn span.cross:before{background-color: #FFF;}
.navbar .menuBtn:hover span.cross{left: 0;}
.navbar .menuBtn .is-active span:first-child{opacity: 0;}
.navbar .menuBtn .is-active span:last-child{opacity: 0;}
.navbar .menuBtn .is-active span.cross{height: 26px;top: 0;left: 0;}
.navbar .menuBtn .is-active span.cross:before{-webkit-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);}
.navbar .menuBtn .is-active span.cross:after {  -webkit-transform: rotate(45deg);  -ms-transform: rotate(45deg);  -o-transform: rotate(45deg);  transform: rotate(45deg);}
.navbar.sticky .menuBtn span,.navbar.white .menuBtn span {background: #012a5e;}
.navbar.sticky .menuBtn span.cross,.navbar.white .menuBtn span.cross {background: 0 0;}
.navbar.sticky .menuBtn span.cross:after,.navbar.sticky .menuBtn span.cross:before,.navbar.white .menuBtn span.cross:after,.navbar.white .menuBtn span.cross:before{background: #012a5e;}
.navbar.sticky .menuBtn a.is-active span,.navbar.white .menuBtn a.is-active span {background: #fff;}
.navbar.sticky .menuBtn a.is-active span.cross,.navbar.white .menuBtn a.is-active span.cross {background: 0 0;}
.navbar.sticky .menuBtn a.is-active span.cross:after,.navbar.sticky .menuBtn a.is-active span.cross:before,.navbar.white .menuBtn a.is-active span.cross:after,.navbar.white .menuBtn a.is-active span.cross:before {background: #fff;}
