///* ----------- Default Styling Start --------------- */

$primary-fontFamily: "Montserrat", sans-serif;
$primary-fontWeight: 400;
$primary-lineHeight: 1.4;
$primary-fontColor: #6e6b7b;
$primary-color: #2056a5;
$primary-hover: #1b427c;
$primary-bgColor: #fafafa;
$color-black: #000000;
$color-white: #ffffff;

/// Viewport
$desktop_xxxl: 1920px;
$desktop_xxl: 1650px;
$desktop_xl: 1440px;
$laptop: 1366px;
$desktop: 1280px;
$desktopSmall: 1024px;
$tabletSmall: 768px;
$mobileBig: 767px;
$mobile: 480px;
$mobileSmall: 360px;

///* ----------- Default Styling End ----------------- */

.dashboard__headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 0px);
    z-index: 2;

    background-color: $primary-bgColor;
    // padding: 17px 0;
    transition: all 0.5s ease;

    .dashboard__heading {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        @media (max-width: $mobileBig) {
         
        }

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.5;
            color: $color-black;
            margin: 0;
        }
    }
}

.uploadingExistingData {
    $uploadingExistingData: ".uploadingExistingData";

    display: flex;
    flex-direction: column;

    padding-top: 70px;

    > .container {
        width: 85%;
        max-width: 1200px;
    }

    .dashboard__body{
        padding: 1rem;
        background-color: #fafafa;
        border-radius: 0.5rem;
        margin-bottom: 3rem;

        .customTable{
            display: flex;
            flex-direction: column;

            .customTable__body{
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
            }

            .customTable__footer{
                display: flex;
                justify-content: flex-end;

                .customTable__right{
                    display: flex;
                    flex-direction: column;

                    .customTable__bottom{
                        display: flex;
                        flex-direction: column;

                        .customTable__buttons{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            span{
                                margin-right: 0.5rem;

                                &[disabled] {
                                    cursor: default;
                                    opacity: 0.7;

                                    &:hover {
                                        border-color: #0d6efd;
                                        color: #0d6efd;
                                        background-color: transparent;
                                    }
                                }

                                &:last-child{
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__main {
        display: flex;
        flex-direction: row;

        padding: 2rem 0;

        @media (max-width: $desktop - 1) {
            flex-direction: column;
        }

        .dashboard__headingBackLink{margin-right: 1rem;}

        #{$uploadingExistingData}__left {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 30%;
            // background-color: #e3f2fd;
            background-color: #ffffff;
            border-radius: 10px;
            margin-right: 5%;
            min-height: calc(100vh - 142px);

            @media (max-width: $desktop - 1) {
                width: 100%;
                margin: 0 0 2rem;
                min-height: calc(50vh - 3rem);
            }

            img {
                width: 40%;
                height: auto;
                object-fit: cover;
            }
        }

        #{$uploadingExistingData}__right {
            display: flex;
            flex-direction: column;
            width: 65%;
            position: relative;
            padding-bottom: 70px;
            align-items: center;
            justify-content: center;
            padding: 1rem 1rem 70px 1rem;
            background-color: $color-white;
            border-radius: 1.5rem;

            @media (max-width: $desktop - 1) {
                width: 100%;
                // height: calc(50vh - 3rem);
                min-height: 360px;
            }
          

            .underprocessbox{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h3{
                    color: $color-black;
                    margin-bottom: 1rem;
                }

                a{
                    color: $color-black;
                    padding: 0.8rem 1rem;
                    border-radius: 2rem;
                    border: 1px solid $color-black;
                }
            }

            #{$uploadingExistingData}__content {
                display: flex;
                flex-direction: column;

                height: 100%;
                // padding-bottom: 1.5rem;
                overflow: auto;

                @media (max-width: $mobileBig) {
                    height: auto;
                }

                #{$uploadingExistingData}__image {
                    display: flex;
                    flex-direction: column;

                    img {
                        width: 75px;
                        margin: 0 auto;
                    }
                }

                #{$uploadingExistingData}__heading {
                    display: flex;
                    flex-direction: column;

                    h1 {
                        font-size: 1.25rem;
                        font-weight: 700;
                        line-height: 1.2;
                        color: #191a1a;
                        margin: 0;
                    }

                    h4 {
                        font-size: 0.8rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #191a1a;
                        margin: 1rem 0 0;
                    }
                }

                #{$uploadingExistingData}__uploadBox {
                    display: flex;
                    flex-direction: column;

                    margin: 1.5rem 0 0;

                    #{$uploadingExistingData}__uploadBoxTop {
                        display: flex;
                        flex-wrap: wrap;

                        margin-bottom: 1rem;


                        .label_select_schema{display: block;margin-bottom: 0.5rem;width: 100%;}

                        .select_schema_form{
                            width: calc(80% - 20px);

                            .css-13cymwt-control{
                                min-height: 27px;
                            }

                            .css-1fdsijx-ValueContainer{
                                font-size: 0.8rem;
                                padding: 0.2rem 0.9rem !important;
                            }
                        }

                        #{$uploadingExistingData}__uploadBoxLabel {
                            width: 100%;

                            margin: 0 0 0.5rem;

                            label {
                                margin: 0;
                            }

                            a{

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 100%;
                                    left: 50%;
                                    margin-left: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: #0d6efd transparent transparent transparent;
                                }

                            }
                            span {
                                color: #0d6efd;
                                position: relative;
                                margin-right: 10px;

                                &.edit_btn{
                                    color: #ccc;
                                    margin-left: 10px;
                                }
                
                                .show___div {
                                    visibility: hidden;
                                    background-color: #e3ebf8;
                                    color: #0a0a0a;
                                    position: absolute;
                                    z-index: 1;
                                    opacity: 0;
                                    transition: opacity 0.3s;
                                    font-size: 11px;
                                    padding: 10px;
                                    border-radius: 5px;
                                    height: 180px;
                                    overflow-y: auto;
                                    width: 300px;

                                    table{
                                        thead{
                                            th{
                                                &:nth-child(2){
                                                    padding-left: 2px;
                                                }
                                                &:last-child{
                                                    padding-left: 2px;
                                                }
                                            }
                                        }
                                        tbody{
                                            tr{
                                                td{
                                                    &:nth-child(2){
                                                        padding-left: 2px;
                                                    }
                                                    &:last-child{
                                                        padding-left: 2px;
                                                    }   
                                                }
                                            }
                                        }
                                    }
                                }
                
                                &:hover {
                                    .show___div {
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }
                            }

                            .htd_label{
                                margin-bottom: 0.25rem;
                                display: block;
                            }

                            h6 {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: #191a1a;
                                margin: 0;
                            }

                            .htd_select{
                                .css-1fdsijx-ValueContainer{
                                    padding: .365rem .75rem !important;
                                    font-size: 0.8rem;

                                    .css-1jqq78o-placeholder{
                                        font-size: 0.8rem;
                                    }

                                    .css-qbdosj-Input{margin: 0;}

                                    .css-1xc3v61-indicatorContainer{
                                        padding: .375rem .75rem !important;
                                    }

                                    .css-1dimb5e-singleValue{font-size: 0.8rem;}
                                }
                            }
                        }

                        #{$uploadingExistingData}__uploadBoxUploadFile {
                            display: flex;
                            align-items: center;

                            width: calc(80% - 1rem);
                            margin-right: 1rem;
                           
                            form {
                                width: 100%;
                                margin: 0;

                                input {
                                }
                            }

                            button {
                                margin-left: 1rem;
                                padding: 0.45rem 1.2rem;
                            }
                        }

                        #{$uploadingExistingData}__uploadBoxDownloadFile {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            width: 20%;
                            

                            a {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                text-align: center;

                                img {
                                    width: 50px;
                                    margin-bottom: 0.5rem;
                                }

                                span {
                                    display: block;
                                    font-size: 0.699rem;
                                    color: $primary-color;
                                    text-decoration: underline !important;
                                }
                            }
                        }
                    }

                    #{$uploadingExistingData}__uploadBoxBottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;

                        &--column {
                            flex-direction: column;
                        }

                        .btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // flex: 1 0 auto;

                            font-weight: 600;
                            border-radius: 1.5rem;
                            padding: 0.6rem 1.8rem;

                            i {
                                margin-right: 5px;
                            }

                            + button {
                                margin-left: 2rem;
                            }
                            @media only screen and (max-width: $mobile){
                                width: 100%;
                            }
                        }

                        a{
                            @media only screen and (max-width: $mobile){
                                display: block;margin-bottom:10px;
                            }
                        }

                        p {
                            font-size: 0.8rem;
                            line-height: 1.4;
                            margin: 1rem 0 0;

                            strong {
                                font-weight: 700;
                            }

                            @media screen and (max-width: $mobile){
                                margin-bottom: 1rem;
                            }
                        }

                        @media only screen and (max-width: $mobile){
                            display: block;
                        }
                    }
                }
            }

            #{$uploadingExistingData}__buttonSection {
                display: flex;
                align-items: center;
                justify-content: space-between;

                position: absolute;
                bottom: 0;
                right: 0;
                text-align: center;
                width: 100%;
                min-height: 53.28px;
                padding: 0.8rem;

                @media (max-width: $mobileBig) {
                    flex-direction: column;

                    position: static;
                }

                #{$uploadingExistingData}__buttonSectionLeft {
                    display: flex;
                    justify-content: flex-start;

                    .btn-link {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        color: #333333;
                    }

                    @media (max-width: $mobile){
                        padding: 0.5rem 2.5rem;
                        background-color: #FFF;
                        border-radius: 1rem;
                        color: #282828;
                        border: 1px solid #282828;
                        margin-bottom: 1rem;
                    }

                    a{
                        @media (max-width: $mobile){
                            color: #282828;
                            font-weight: 600;
                        }
                    }
                }

                #{$uploadingExistingData}__buttonSectionRight {
                    display: flex;
                    justify-content: flex-end;

                    width: 100%;

                    @media (max-width: $mobileBig) {
                        flex-direction: column;
                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        border-radius: 1.5rem;
                        padding: 0.6rem 1.8rem;

                        &:last-child {
                            margin-left: 1rem;
                        }

                        i {
                            margin-left: 0.5rem;
                        }

                        @media (max-width: $mobileBig) {
                            margin-bottom: 1rem;

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.analyticalReport {
    display: flex;
    flex-direction: column;
    padding-top: 63px;

    .analyticalReportRFMAnalysis {
        $analyticalReportRFMAnalysis: ".analyticalReportRFMAnalysis";
        &__topSection {
            // padding: 2rem 0;
            background-color: #d9eeff;

            > .container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: $mobileBig) {
                    flex-direction: column;
                }
            }

            #{$analyticalReportRFMAnalysis}__leftSection {
                display: flex;
                flex-direction: column;

                width: 70%;

                @media (max-width: $mobileBig) {
                    width: 100%;
                    margin: 0 0 2rem;
                }

                h4 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    margin: 0 0 0.5rem;
                }

                h2 {
                    font-size: 2rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: #006bc0;
                    margin: 0;
                }

                p{
                    color: $color-black;
                    margin-bottom: 0;
                }
            }

            #{$analyticalReportRFMAnalysis}__rightSection {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                width: 30%;
                padding: 1rem 0;

                @media (max-width: $mobileBig) {
                    width: 100%;
                }

                h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    padding: 0 0 0.5rem;
                    border-bottom: 1px solid $primary-color;
                    margin: 0 0 0.5rem;

                    sup {
                        color: #006bc0;
                        margin-right: 5px;
                    }
                }

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $color-black;
                    margin: 0;
                }
            }
        }

        &__bottomSection {
            display: flex;
            flex-direction: column;

            padding: 2rem 0;

            #{$analyticalReportRFMAnalysis}__paragraph {
                display: flex;
                flex-direction: column;

                margin: 0 0 2rem;

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    line-height: 1.2;
                    color: $color-black;
                    margin: 0;

                    @media (max-width: $mobileBig){
                        line-height: 1.54;
                    }

                    strong {
                        font-weight: 700;
                    }
                }
                p:nth-child(1){margin-bottom: 10px;}
            }

            #{$analyticalReportRFMAnalysis}__content {
                display: flex;
                flex-direction: column;

                padding-left: 3rem;
                padding-bottom: 3.5rem;
                position: relative;
                overflow: auto;

                @media (max-width: $mobileBig) {
                    padding-left: 4rem;
                    padding-bottom: 4.5rem;
                }

                #{$analyticalReportRFMAnalysis}__yAxis {
                    display: flex;
                    flex-direction: column;

                    position: absolute;
                    top: 250px;
                    left: 20px;
                    width: 500px;
                    height: auto;
                    transform: translate(-50%, -50%) rotate(-90deg);
                    text-align: center;

                    .label {
                        font-size: 0.8rem;
                        font-weight: 400;
                        // font-family: "Noto Sans", sans-serif;
                        color: #0c1331;
                        line-height: 1.4;
                        margin-bottom: 0.5rem;
                    }

                    ul {
                        display: flex;
                        flex-direction: row;

                        margin: 0;
                        padding: 0;

                        li {
                            display: block;
                            width: 20%;

                            transform: rotate(90deg);

                            &.legend {
                                font-size: 0.8rem;
                                font-weight: 400;
                                font-family: "Noto Sans", sans-serif;
                                color: #0c1331;
                                line-height: 1.4;
                            }
                        }
                    }
                }
                #{$analyticalReportRFMAnalysis}__details i {
                    display: inline-block;
                    background-color: #fff;
                    color: #000;
                    position: absolute;
                    right: 5px;
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    text-align: center;
                    font-style: normal;
                    line-height: 15px;
                    font-size: 10px;
                    font-weight: 700;
                    top: 5px;
                    cursor: pointer;
                }
                #{$analyticalReportRFMAnalysis}__boxes {
                    display: flex;
                    flex-wrap: wrap;

                    width: 100%;
                    height: 100%;
                    min-width: 500px;
                    min-height: 500px;
                    position: relative;
                    z-index: 0;

                    #{$analyticalReportRFMAnalysis}__box {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;

                        position: absolute;
                        padding: 1rem;
                        border: 1px solid transparent;
                        border-radius: 0.75rem;
                        z-index: 1;
                        cursor: default;

                        &--first {
                            background-color: #f8eaeb;
                            border-color: #d17e81;

                            top: 0;
                            left: 0;

                            width: calc(40% - 15px);
                            height: calc(20% - 15px);
                        }

                        &--second {
                            background-color: #eae7ef;
                            border-color: #c4bcd3;

                            top: 0;
                            left: 40%;

                            width: calc(40% - 15px);
                            height: calc(40% - 15px);
                        }

                        &--third {
                            background-color: #dde9f4;
                            border-color: #92b9db;

                            top: 0;
                            left: 80%;

                            width: 20%;
                            height: calc(40% - 15px);
                        }

                        &--fourth {
                            background-color: #fff3ee;
                            border-color: #e3b7a3;

                            top: 20%;
                            left: 0;

                            width: calc(40% - 15px);
                            height: calc(40% - 15px);
                        }

                        &--fifth {
                            background-color: #fff3d4;
                            border-color: #d9cba5;

                            top: 40%;
                            left: 40%;

                            width: calc(20% - 15px);
                            height: calc(20% - 15px);
                        }

                        &--sixth {
                            background-color: #e1fff0;
                            border-color: #a8e0c4;

                            top: 40%;
                            left: 60%;

                            width: 40%;
                            height: calc(40% - 15px);
                        }

                        &--seventh {
                            background-color: #f4f6f8;
                            border-color: #a8b9c6;

                            top: 60%;
                            left: 0;

                            width: calc(40% - 15px);
                            height: 40%;
                        }

                        &--eighth {
                            background-color: #e9fafb;
                            border-color: #9ed7d8;

                            top: 60%;
                            left: 40%;

                            width: calc(20% - 15px);
                            height: 40%;
                        }

                        &--ninth {
                            background-color: #f8f2df;
                            border-color: #d3c697;

                            top: 80%;
                            left: 60%;

                            width: calc(20% - 15px);
                            height: 20%;
                        }

                        &--tenth {
                            background-color: #f6fcdc;
                            border-color: #afb983;

                            top: 80%;
                            left: 80%;

                            width: 20%;
                            height: 20%;
                        }

                        #{$analyticalReportRFMAnalysis}__details {
                            display: flex;
                            flex-direction: column;

                            text-align: right;

                            h3 {
                                font-size: 0.8rem;
                                font-weight: 700;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                          
                        }
                    }
                }

                #{$analyticalReportRFMAnalysis}__xAxis {
                    display: flex;
                    flex-direction: column;

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                    text-align: center;

                    @media (max-width: $mobileBig){
                        min-width: 500px;
                        margin-left: 15%;
                    }

                    .label {
                        font-size: 0.8rem;
                        font-weight: 400;
                        // font-family: "Noto Sans", sans-serif;
                        color: #0c1331;
                        line-height: 1.4;
                        margin-top: 0.5rem;
                    }

                    ul {
                        display: flex;
                        flex-direction: row;

                        margin: 0;
                        padding: 0;

                        li {
                            display: block;
                            width: 20%;

                            &.legend {
                                font-size: 0.8rem;
                                font-weight: 400;
                                font-family: "Noto Sans", sans-serif;
                                color: #0c1331;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }

    .analyticalReportChartAnalysisOne {
        $analyticalReportChartAnalysisOne: ".analyticalReportChartAnalysisOne";

        #{$analyticalReportChartAnalysisOne}__topSection {
            display: flex;
            flex-wrap: wrap;

            margin: 0 0 2rem;

            #{$analyticalReportChartAnalysisOne}__heading {
                display: flex;
                flex-direction: column;

                margin: 0 0 1.2rem;
                width: 100%;

                h2 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    letter-spacing: 1px;
                    margin: 0;
                }
            }

            #{$analyticalReportChartAnalysisOne}__fullSection {
                width: 100%;
            }

            #{$analyticalReportChartAnalysisOne}__box {
                display: flex;
                flex-direction: column;

                border-radius: 1.5rem;
                width: 100%;

                #{$analyticalReportChartAnalysisOne}__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid $primary-color;
                    border-bottom: none;
                    padding: 1rem;
                    border-top-left-radius: 1.5rem;
                    border-top-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisOne}__left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        #{$analyticalReportChartAnalysisOne}__image {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 2.5rem;
                            height: 2.5rem;
                            background-color: #d9eeff;
                            border-radius: 0.75rem;
                            margin-right: 0.5rem;

                            img {
                                width: 50%;
                            }
                        }

                        #{$analyticalReportChartAnalysisOne}__details {
                            display: flex;
                            flex-direction: column;

                            h3 {
                                font-size: 1rem;
                                font-weight: 700;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                            
                        }
                    }

                    #{$analyticalReportChartAnalysisOne}__right {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        #{$analyticalReportChartAnalysisOne}__select {
                            display: flex;
                            flex-direction: column;

                            .form-control {
                                display: block;

                                &.input-sm {
                                    font-size: 0.7rem;
                                    padding: 5px 7.5px;
                                }
                            }
                        }

                        #{$analyticalReportChartAnalysisOne}__button {
                            display: flex;
                            flex-direction: column;

                            margin-left: 0.5rem;

                            button {
                                display: block;
                                padding: 5px 7.5px;

                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                #{$analyticalReportChartAnalysisOne}__chartSection {
                    border: 1px solid $primary-color;
                    padding: 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisOne}__lineChartReport,
                    #{$analyticalReportChartAnalysisOne}__barChartReport {
                        width: 100%;
                        height: 100%;

                        canvas {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }

        #{$analyticalReportChartAnalysisOne}__bottomSection {
            display: flex;
            flex-wrap: wrap;
            margin: 2rem 0 2rem 0;

            #{$analyticalReportChartAnalysisOne}__heading {
                display: flex;
                flex-direction: column;

                margin: 0 0 1rem;
                width: 100%;

                h2 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    letter-spacing: 1px;
                    margin: 0;
                }
            }

            #{$analyticalReportChartAnalysisOne}__leftSection {
                width: calc(40% - 10px);
                margin-right: 20px;

                &.fullSize {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @media (max-width:767px) {
                        flex-direction: column;
                    }
                    .analyticalReportChartAnalysisOne__box {
                        width: calc(100% / 3 - 15px);
                        vertical-align: top;
                        margin-top: 0;
                        @media (max-width:767px) {
                            width: 100%;
                            margin-bottom: 10px;
                        }
                    }
                }

                @media (max-width: $mobileBig) {
                    width: 100%;
                    margin: 0 0 2rem;
                }
            }

            #{$analyticalReportChartAnalysisOne}__rightSection {
                width: calc(60% - 10px);

                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            #{$analyticalReportChartAnalysisOne}__box {
                display: flex;
                flex-direction: column;
                //background-color: #FFF;
                border-radius: 1.5rem;
                margin-top: 1rem;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                &:first-child {
                    margin-top: 0;
                }

                #{$analyticalReportChartAnalysisOne}__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid $primary-color;
                    //border: 1px solid rgb(231,233,235);
                    border-bottom: none;
                    padding: 1rem;
                    border-top-left-radius: 1.5rem;
                    border-top-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisOne}__left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        #{$analyticalReportChartAnalysisOne}__image {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 2.5rem;
                            height: 2.5rem;
                            background-color: #d9eeff;
                            border-radius: 0.75rem;
                            margin-right: 0.5rem;

                            img {
                                width: 50%;
                            }
                        }

                        #{$analyticalReportChartAnalysisOne}__details {
                            display: flex;
                            flex-direction: column;

                            h3 {
                                font-size: 1rem;
                                font-weight: 700;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                        }
                    }

                    #{$analyticalReportChartAnalysisOne}__right {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        #{$analyticalReportChartAnalysisOne}__select {
                            display: flex;
                            flex-direction: column;

                            .form-control {
                                display: block;

                                &.input-sm {
                                    font-size: 0.7rem;
                                    padding: 5px 7.5px;
                                }
                            }
                        }

                        #{$analyticalReportChartAnalysisOne}__button {
                            display: flex;
                            flex-direction: column;

                            margin-left: 0.5rem;

                            button {
                                display: block;
                                padding: 5px 7.5px;

                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                #{$analyticalReportChartAnalysisOne}__chartSection {
                    display: flex;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    //border: 1px solid rgb(231,233,235);
                    padding: 5rem 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisOne}__lineChartReport,
                    #{$analyticalReportChartAnalysisOne}__barChartReport {
                        width: 100%;
                        height: 100%;

                        canvas {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }

                    @media screen and (max-width: $mobileBig){
                        padding: 1rem;
                    }
                }

                #{$analyticalReportChartAnalysisOne}__reportSection {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    //border: 1px solid rgb(231,233,235);
                    padding: 1.5rem 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    h3 {
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 1.2;
                        color: #9a00c0;
                        //color: #0070BB;
                        margin: 0 0 0.25rem;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: $color-black;
                        margin: 0;
                    }
                    &.avgSpend {
                        padding-bottom: 53px;
                    }
                }
            }
        }
    }

    .analyticalReportChartAnalysisTwo {
        $analyticalReportChartAnalysisTwo: ".analyticalReportChartAnalysisTwo";

        #{$analyticalReportChartAnalysisTwo}__topSection {
            display: flex;
            flex-wrap: wrap;

            margin: 0 0 2rem;

            #{$analyticalReportChartAnalysisTwo}__heading {
                display: flex;
                flex-direction: column;

                margin: 0 0 1rem;
                width: 100%;

                h2 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    letter-spacing: 1px;
                    margin: 0;
                }
            }

            #{$analyticalReportChartAnalysisTwo}__leftSection {
                width: calc(65% - 10px);
                margin-right: 20px;

                @media (max-width: $mobileBig) {
                    width: 100%;
                    margin: 0 0 2rem;
                }
            }

            #{$analyticalReportChartAnalysisTwo}__rightSection {
                width: calc(35% - 10px);

                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            #{$analyticalReportChartAnalysisTwo}__box {
                display: flex;
                flex-direction: column;

                border-radius: 1.5rem;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }

                #{$analyticalReportChartAnalysisTwo}__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid $primary-color;
                    border-bottom: none;
                    padding: 1rem;
                    border-top-left-radius: 1.5rem;
                    border-top-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisTwo}__left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        #{$analyticalReportChartAnalysisTwo}__image {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 2.5rem;
                            height: 2.5rem;
                            background-color: #d9eeff;
                            border-radius: 0.75rem;
                            margin-right: 0.5rem;

                            img {
                                width: 50%;
                            }
                        }

                        #{$analyticalReportChartAnalysisTwo}__details {
                            display: flex;
                            flex-direction: column;

                            h3 {
                                font-size: 1rem;
                                font-weight: 700;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                        }
                    }

                    #{$analyticalReportChartAnalysisTwo}__right {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        #{$analyticalReportChartAnalysisTwo}__select {
                            display: flex;
                            flex-direction: column;

                            .form-control {
                                display: block;

                                &.input-sm {
                                    font-size: 0.7rem;
                                    padding: 5px 7.5px;
                                }
                            }
                        }

                        #{$analyticalReportChartAnalysisTwo}__button {
                            display: flex;
                            flex-direction: column;

                            margin-left: 0.5rem;

                            button {
                                display: block;
                                padding: 5px 7.5px;

                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                #{$analyticalReportChartAnalysisTwo}__chartSection {
                    display: flex;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    padding: 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisTwo}__lineChartReport,
                    #{$analyticalReportChartAnalysisTwo}__barChartReport,
                    #{$analyticalReportChartAnalysisTwo}__doughnutChartReport {
                        width: 100%;
                        height: 100%;

                        canvas {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                    &.genderBifurc {
                        padding-bottom: 31px;
                    }
                }

                #{$analyticalReportChartAnalysisTwo}__reportSection {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    padding: 2.5rem 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    h3 {
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 1.2;
                        color: #9a00c0;
                        margin: 0 0 0.25rem;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: $color-black;
                        margin: 0;
                    }
                }
            }
        }

        #{$analyticalReportChartAnalysisTwo}__bottomSection {
            display: flex;
            flex-wrap: wrap;

            margin: 0 0 2rem;

            #{$analyticalReportChartAnalysisTwo}__heading {
                display: flex;
                flex-direction: column;

                margin: 0 0 1rem;
                width: 100%;

                h2 {
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1.2;
                    color: $color-black;
                    letter-spacing: 1px;
                    margin: 0;
                }
            }

            #{$analyticalReportChartAnalysisTwo}__leftSection {
                width: calc(65% - 10px);
                margin-right: 20px;

                @media (max-width: $mobileBig) {
                    width: 100%;
                    margin: 0 0 2rem;
                }
            }

            #{$analyticalReportChartAnalysisTwo}__rightSection {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                width: calc(35% - 10px);
                text-align: center;

                @media (max-width: $mobileBig) {
                    width: 100%;
                }

                #{$analyticalReportChartAnalysisTwo}__paragraph {
                    display: flex;
                    flex-direction: column;

                    margin: 0 0 2rem;

                    p {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: $color-black;
                        margin: 0;
                    }
                }

                #{$analyticalReportChartAnalysisTwo}__buttonSection {
                    display: flex;
                    flex-direction: column;

                    .btn {
                        display: block;
                        border-radius: 1.5rem;
                        padding: 0.6rem 1.8rem;
                        margin: 0 0 1rem;

                        &:last-child {
                            margin: 0;
                        }

                        i {
                            margin-left: 0.5rem;
                            font-size: 1rem;
                        }
                    }

                    .btn-link {
                        display: block;
                        color: #333333;
                    }
                }
            }

            #{$analyticalReportChartAnalysisTwo}__box {
                display: flex;
                flex-direction: column;

                border-radius: 1.5rem;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }

                #{$analyticalReportChartAnalysisTwo}__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border: 1px solid $primary-color;
                    border-bottom: none;
                    padding: 1rem;
                    border-top-left-radius: 1.5rem;
                    border-top-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisTwo}__left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        #{$analyticalReportChartAnalysisTwo}__image {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 2.5rem;
                            height: 2.5rem;
                            background-color: #d9eeff;
                            border-radius: 0.75rem;
                            margin-right: 0.5rem;

                            img {
                                width: 50%;
                            }
                        }

                        #{$analyticalReportChartAnalysisTwo}__details {
                            display: flex;
                            flex-direction: column;

                            h3 {
                                font-size: 1rem;
                                font-weight: 700;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                        }
                    }

                    #{$analyticalReportChartAnalysisTwo}__right {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        #{$analyticalReportChartAnalysisTwo}__select {
                            display: flex;
                            flex-direction: column;

                            .form-control {
                                display: block;

                                &.input-sm {
                                    font-size: 0.7rem;
                                    padding: 5px 7.5px;
                                }
                            }
                        }

                        #{$analyticalReportChartAnalysisTwo}__button {
                            display: flex;
                            flex-direction: column;

                            margin-left: 0.5rem;

                            button {
                                display: block;
                                padding: 5px 7.5px;

                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                #{$analyticalReportChartAnalysisTwo}__chartSection {
                    display: flex;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    padding: 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    #{$analyticalReportChartAnalysisTwo}__lineChartReport {
                        width: 100%;
                        //width: fit-content !important;
                        height: 100%;
                        max-width: 100%;

                        //width: fit-content;
                        //height: fit-content;

                        canvas {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }

                #{$analyticalReportChartAnalysisTwo}__reportSection {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    border: 1px solid $primary-color;
                    padding: 2.5rem 1rem;
                    border-bottom-left-radius: 1.5rem;
                    border-bottom-right-radius: 1.5rem;

                    h3 {
                        font-size: 1.8rem;
                        font-weight: 700;
                        line-height: 1.2;
                        color: #9a00c0;
                        margin: 0 0 0.25rem;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.2;
                        color: $color-black;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.businessGoalsSelection {
    $businessGoalsSelection: ".businessGoalsSelection";
    padding-top: 63px;

    .container {
        width: 85%;
        max-width: 1200px;

        @media screen and (max-width: $mobileBig) {
            width: 90%;
        }
    }

    &__topSection {
        padding: 4rem 0;
        background-color: #d9eeff;

        @media screen and (max-width: $mobileBig) {
            padding: 2rem 0;
        }

        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        #{$businessGoalsSelection}__leftSection {
            display: flex;
            flex-direction: column;

            width: 70%;

            @media (max-width: $mobileBig) {
                width: 100%;
            }

            h4 {
                font-size: 1.75rem;
                font-weight: 500;
                line-height: 1.2;
                color: $color-black;
                margin: 0;

                @media (max-width: $mobileBig) {
                    font-size: 2rem;
                }
            }
        }
    }

    &__bottomSection {
        display: flex;
        flex-direction: column;

        padding: 2rem 0;

        #{$businessGoalsSelection}__paragraph {
            display: flex;
            flex-direction: column;

            margin: 0 0 2rem;

            p {
                font-size: 0.8rem;
                font-weight: 400;
                line-height: 1.2;
                color: $color-black;
                margin: 0;

                strong {
                    font-weight: 700;
                }
            }
        }

        #{$businessGoalsSelection}__accordion {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            margin: 0 0 2rem;

            #{$businessGoalsSelection}__accordionItem {
                display: flex;
                flex-direction: column;

                padding: 0;
                border: 1px solid transparent;
                border-radius: 0.75rem;
                background-color: transparent;
                margin: 0 0 1rem;
                width: calc(50% - 10px);

                &:nth-last-child(2),
                &:nth-last-child(1) {
                    margin: 0;
                }

                @media (max-width: $desktop - 1) {
                    width: 100%;
                    margin: 0 0 1rem;

                    &:nth-last-child(2) {
                        margin: 0 0 1rem;
                    }
                }

                #{$businessGoalsSelection}__accordionHeading {
                    display: flex;
                    flex-direction: column;

                    padding: 0;
                    border: none;
                    border-radius: 0;
                    background-color: transparent;

                    #{$businessGoalsSelection}__accordionButton {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        font-size: 1rem;
                        font-weight: 700;
                        line-height: 1.2;
                        color: $color-black;
                        border: none;
                        text-align: left;
                        padding: 20px 40px 20px 55px;
                        position: relative;
                        transition: all 0.5s ease;
                        border-radius: 15px;
                        background-color: #f5f5f5;

                        input[type="checkbox"] {
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            top: 22px;
                            left: 1rem;
                            margin: 0;
                        }

                        a {
                            color: $color-black;
                            position: absolute;
                            top: 50%;
                            right: 1rem;
                            transform: translateY(-50%) rotate(0deg);
                            transition: all 0.5s ease;

                            i {
                                font-size: 1rem;
                                color: $color-black;
                            }
                        }

                        &.active {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            // background-color: #e3f2fd;
                            background-color: #ffffff;

                            a {
                                transform: translateY(-50%) rotate(90deg);
                            }
                        }

                        @media (max-width: $mobileBig) {
                            font-size: 1.5rem;

                            input {
                                top: 19px;
                                left: 1.5rem;
                            }
                        }
                    }
                }

                #{$businessGoalsSelection}__accordionCollapse {
                    margin: 0;

                    #{$businessGoalsSelection}__accordionBody {
                        font-size: 0.8rem;
                        font-weight: 400;
                        line-height: 1.75;
                        color: $color-black;
                        margin: 0;
                        padding: 0 1rem 1rem 2.75rem;
                        // background-color: #e3f2fd;
                        background-color: #ffffff;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
                    }
                }
            }
        }

        #{$businessGoalsSelection}__buttonSection {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            min-height: 53.28px;

            @media (max-width: $mobileBig) {
                flex-direction: column;
            }

            #{$businessGoalsSelection}__buttonSectionLeft {
                display: flex;
                align-items: flex-start;

                .btn-link {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    color: #333333;

                    @media (max-width: $mobileBig) {
                        margin: 0 0 1rem;
                    }
                }

                @media (max-width: $mobileBig) {
                    padding: 0.5rem 2.5rem;
                    background-color: #FFF;
                    border-radius: 1rem;
                    color: #282828;
                    border: 1px solid #282828;
                    margin-bottom: 1rem;
                }

                a{
                    @media (max-width: $mobileBig){
                        color: #282828;
                    }
                }
            }

            #{$businessGoalsSelection}__buttonSectionRight {
                display: flex;
                align-items: flex-end;

                @media (max-width: $mobileBig) {
                    align-items: center;
                    flex-direction: column;
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border-radius: 1.5rem;
                    padding: 0.6rem 1.8rem;

                    &:last-child {
                        margin-left: 1rem;
                    }

                    i {
                        margin-left: 0.5rem;
                    }

                    @media (max-width: $mobileBig) {
                        margin: 0 0 1rem;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.campaignSelection {
    $campaignSelection: ".campaignSelection";

    padding-top: 70px;

    .container {
        width: 85%;
        max-width: 1200px;
    }

    &__topSection {
        padding: 2rem 0;
        background-color: #d9eeff;

        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: $mobileBig) {
                flex-direction: column;
            }
        }

        #{$campaignSelection}__leftSection {
            display: flex;
            flex-direction: column;

            width: 70%;

            @media (max-width: $mobileBig) {
                width: 100%;
                margin: 0 0 2rem;
            }

            h4 {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.2;
                color: $color-black;
                margin: 0 0 0.5rem;
            }

            h2 {
                font-size: 2rem;
                font-weight: 600;
                line-height: 1.2;
                color: #006bc0;
                margin: 0;
            }
        }

        #{$campaignSelection}__rightSection {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            width: 30%;

            @media (max-width: $mobileBig) {
                width: 100%;
            }

            h3 {
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.2;
                color: $color-black;
                padding: 0 0 0.5rem;
                border-bottom: 1px solid $primary-color;
                margin: 0 0 0.5rem;

                sup {
                    color: #006bc0;
                    margin-right: 5px;
                }
            }

            p {
                font-size: 0.8rem;
                font-weight: 400;
                line-height: 1.5;
                color: $color-black;
                margin: 0;
            }
        }
    }

    &__bottomSection {
        display: flex;
        flex-direction: column;

        padding: 2rem 0;

        #{$campaignSelection}__heading {
            display: flex;
            flex-direction: column;

            margin: 0;

            h2 {
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.2;
                color: $color-black;
                margin: 0;
            }
        }

        #{$campaignSelection}__boxes {
            margin: 0 0 3rem;

            .slick-slider {
                display: flex;
                flex-direction: column;

                .slick-arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: $color-black;
                    width: 30px;
                    height: 30px;

                    &::before {
                        display: none;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 30px;
                    }

                    &.slick-prev {
                        left: -40px;

                        @media (max-width: $mobileBig){
                            left: -30px;
                        }
                    }

                    &.slick-next {
                        right: -23px;

                        @media (max-width: $mobileBig){
                            right: -30px;
                        }
                    }
                }

                .slick-list {
                    padding: 0 !important;

                    .slick-track {
                        // width: 100% !important;

                        .slick-cloned {
                            // display: none !important;
                        }
                    }
                }
            }

            #{$campaignSelection}__box {
                display: flex;
                flex-direction: column;

                padding-top: 55px;
                padding-right: 1rem;

                @media (max-width: $mobileBig) {
                    padding-right: 0;
                }

                #{$campaignSelection}__boxTop {
                    display: flex;
                    flex-direction: column;

                    margin: 0 0 1.5rem;

                    position: relative;
                    border: 1px solid $primary-color;
                    border-radius: 0.75rem;
                    padding: 1rem;
                    padding-top: 55px;
                    // background-color: #e3f2fd;
                    background-color: #ffffff;

                    #{$campaignSelection}__editCampaign {
                        position: absolute;
                        top: 0.8rem;
                        right: 0.8rem;
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        width: 25px;
                        height: 25px;

                        i {
                            font-size: 1rem;
                        }
                    }

                    #{$campaignSelection}__image {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 75px;
                        height: 75px;
                        border-radius: 50%;
                        // background-color: $primary-color;
                        position: absolute;
                        top: 0;
                        left: 1.5rem;
                        transform: translateY(-50%);

                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
                    }

                    #{$campaignSelection}__content {
                        display: flex;
                        flex-direction: column;

                        h3 {
                            //font-size: 1rem;
                            font-weight: 700;
                            line-height: 1.2;
                            color: $color-black;
                            margin: 0 0 1rem;
                            font-size: calc(100%);

                            span {
                                display: block;
                                font-size: 0.7rem;
                                font-weight: 400;
                                color: #999999;
                                margin-top: 5px;
                            }
                        }

                        p {
                            font-size: 0.8rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 1rem;
                        }

                        .campaignExpirationRule {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            span {
                                font-size: 0.7rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;

                                strong {
                                    font-size: 0.65rem;
                                }
                            }
                        }
                    }
                }

                #{$campaignSelection}__boxBottom {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .btn {
                        display: block;
                        padding: 0.6rem 1.8rem;
                        border-radius: 1rem;
                    }
                }
            }
        }

        #{$campaignSelection}__buttonSection {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            min-height: 53.28px;

            @media (max-width: $mobileBig) {
                flex-direction: column;
            }

            #{$campaignSelection}__buttonSectionLeft {
                display: flex;
                align-items: flex-start;

                .btn-link {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    color: #333333;

                    @media (max-width: $mobileBig) {
                        margin: 0 0 1rem;
                    }
                }

                @media (max-width: $mobileBig) {
                    padding: 0.5rem 2.5rem;
                    background-color: #FFF;
                    border-radius: 1rem;
                    color: #282828;
                    border: 1px solid #282828;
                    margin-bottom: 1rem;
                }

                a{
                    @media (max-width: $mobileBig){
                        color: #282828;
                    }
                }
            }

            #{$campaignSelection}__buttonSectionRight {
                display: flex;
                align-items: flex-end;

                @media (max-width: $mobileBig) {
                    align-items: center;
                    flex-direction: column;
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    border-radius: 1.5rem;
                    padding: 0.6rem 1.8rem;

                    &:last-child {
                        margin-left: 1rem;
                    }

                    i {
                        margin-left: 0.5rem;
                    }

                    @media (max-width: $mobileBig) {
                        margin: 0 0 1rem;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

// .analyticalReport .analyticalReportChartAnalysisTwo .analyticalReportChartAnalysisTwo__bottomSection .analyticalReportChartAnalysisTwo__box .analyticalReportChartAnalysisTwo__chartSection .analyticalReportChartAnalysisTwo__lineChartReport.lineChartReport__{max-width: fit-content;}

.analyticalReport .analyticalReportChartAnalysisTwo .analyticalReportChartAnalysisTwo__bottomSection.analyticalReportChartAnalysisTwo__bottomSection_2{flex-direction: column-reverse;flex-wrap: wrap-reverse;}
.analyticalReport .analyticalReportRFMAnalysis__topSection.analyticalReportRFMAnalysis__topSection__left > .container{flex-direction: column-reverse;flex-wrap: wrap-reverse;align-items: end;}


.uploadedfileprocessing{
    h2{
        font-size: 1.8rem;
        color: $color-black;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    ul{
        list-style: square;
        padding-left: 1rem;

        li{
            font-size: 1rem;
            color: $color-black;
            margin-bottom: 0.5rem;
            font-weight: 500;

            span{
                color: $primary-color;
            }
        }
    }
}
.emailText{
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    color: #000000;
    margin: 0;
}