.dashboard__customerViewCard{
    .query_builder_row{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .query_builder_box{
            width: 50%;

            .person{
                display: flex;
                justify-content: end;
                align-items: center;

                p{
                    margin-right: 1rem;
                }
            }
        }

        @media only screen and (max-width: 768px){
            flex-wrap: wrap;
            margin-bottom: 1rem;

            .query_builder_box{
                width: 100%;
            }
        }
    }
}

@import 'react-querybuilder/dist/query-builder.scss';



  
.queryBuilder {
    .ant-input {
        width: auto;
    }
    .ant-btn.css-dev-only-do-not-override-nllxry.ant-btn-primary.ruleGroup-addGroup span::after {
        content: " Rule";
    }
    select,input{
        padding: 8px 10px;
        border: 1px solid #afafaf;
        border-radius: 4px;
    }
    button{
        padding: 8px 10px;
        background-color: #2056a5;
        color: #fff;
        border: 1px solid #f7faff;
        border-radius: 4px;
        font-weight: 700;
        background: linear-gradient( #2056a5, #4384e7);
        &.rule-remove {
            width: 38px;
        }
    }

}

@media only screen and (max-width: 600px){
    .ruleGroup .ruleGroup-header, .ruleGroup .rule{
        flex-wrap: wrap;
    }
}


