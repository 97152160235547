.custom_report_mainbox{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .custom_report_selector{
        width: 80%;

        .custom_report_selector_row{
            display: flex;
            align-items: center;
            justify-content: start;
        }

        .compare_month_report_box{
            padding: 10px;
            background-color: #e3f2fd;
            border-radius: 4px;
            border: 1px solid #ccc;

            .col-md-6{

                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }

    .custom_report_button{
        width: 20%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        flex-wrap: wrap;

        .custom_report_selector{
            width: 100%;

            .compare_month_report_box{
                margin-top: 10px;
            }
        }

        .custom_report_button{
            width: 100%;
        }

    }
}

.dashboard__customersCard{

    .dashboard__body{

        min-height: 450px;
    }    
}

.cs_report_mainbox{
    display: flex;
    align-items: start;
    justify-content: start;

    .cs_report_box{
        width: calc(100%/3 - 10px);
        margin-right: 10px;
        position: relative;

        .cs_reports_mainbox{
            display: none;

            &.active{                
                display: block;
                width: 100%;
                position: absolute;
            
            }

            .cs_report_box_results{

                .cs_report_space{
                    margin-top: 10px;
                }

                .cs_report_btns{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;

                    .reseter_btn{
                        display: flex;
                        width: calc(50% - 5px);
                        margin-right: 5px;

                        &:last-child{
                            margin-right: 0;
                        }
        
                        button{
                            padding: 10px;
                            border: none;
                            width: 100%;
                            background-color: #E5E4E2;
                            border-radius: 4px;
                            color: #000;
                            font-weight: 600;
                            cursor: pointer;

                            &:hover{
                                background-color: #DBD7D2;
                            }
                        }
                    }
                }
            }
        }

        .cs_report_box_header{
            background-color: #E5E4E2;
            color: #000;
            font-weight: 600;
            padding: 15px;
            border-radius: 4px;
            position: relative;
            align-items: center;
            display: flex;
            cursor: pointer;

            &:hover{
                background-color: #DBD7D2;
            }

            &:after{
                content: '';
                width: 12px;
                height: 12px;
                display: inline-block;
                border: none;
                border-right: 1px solid #000;
                border-bottom: 1px solid #000;
                position: absolute;
                right: 15px;
                transform: rotate(45deg);
            }
            
        }

        .cs_report_box_results{
            padding: 15px;
            background-color: #FFF;
            border-bottom-left-radius: 8px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-bottom-right-radius: 8px;
        }
    }
}
