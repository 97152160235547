$mobileBig: 767px;

.bookademopopupbox{
    max-width: 500px;margin: 0 auto;padding-top: 100px;z-index: 0;position: relative;
}
.bookademopopupbox h4{color: #000;font-size: 24px;text-align: center;}
.bookademopopupbox p{font-size: 20px;margin-bottom: 15px;color:#000;text-align: center;}
.bookademopopupbox figure{text-align: center;}
.bookademopopup .modal-content img{text-align: center;margin: 0 auto;margin-bottom: 15px;}

.bookademopopupbox input[type=text],.bookademopopupbox input[type=email],.bookademopopupbox input[type=number],.bookademopopupbox textarea{color: #121111;outline: none;border-radius: 4px;position: relative;z-index: 99;box-shadow: none;margin: 0;margin-bottom: 20px;padding: 10px 15px !important;font-size: 18px !important}
.bookademopopupbox button{width: 100%;background-color: #ff9900;border: none;border-radius: 4px;color: #ffffff;font-size: 18px;padding: 12px;}
.bookademopopupbox button:hover{background: #ec8f04;}
.bookademo_formbox span.textValidatorError{margin-bottom: 10px;position: relative;display: flex;}

@media (max-width: $mobileBig) {
    .bookademopopupbox{
        width: 100%;
        max-width: 100%;
        padding: 100px 15px 0 15px;
    } 
}

.contact_header{margin: 0;padding: 0 15px;}
.contact_header_container{display: flex;align-items: center;justify-content: flex-start;padding: 15px 0;background-color: #fafafa;}
.contact_header_logo{width: 20%;}
.contact_header_logo img{width: 120px;}
.contact_header_heading{width: 80%;font-size: 28px;font-weight: 700;color: #282828;text-align: center;}

.sign_in_btn{width: 100%;background-color: #0a6ebe;border: none;border-radius: 4px;color: #ffffff;font-size: 18px;padding: 12px;display: block;text-align: center;margin-top: 15px;}
.sign_in_btn:hover{background-color: #055596;color: #FFF;}

@media (max-width: $mobileBig) {
    .contact_header_heading{
        text-align: right;
        font-size: 18px;
    }
}
