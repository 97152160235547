.no_record_fullbox{height: 350px;}
.report_box_row{
    margin-top: 20px;
    align-items: start;
}
.compare_box{
    display: flex;
    align-items: center;
    justify-content: space-between;

    label{
        font-size: 12px;
    }

}
.react-datepicker-wrapper{

    width: 100%;
    
    .react-datepicker__input-container{
        input{
            font-size: 1rem;
            padding: 0.65rem 0.9rem !important;
            border-color: hsl(0, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            min-height: 38px;
            color: hsl(0, 0%, 20%);
            width: 100%;
        }
    }
}

.dashcheckMainBox{
    // display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #d9ebea;
    border: 1px solid #d9ebea;
    border-radius: 5px;

    

    .dashcheckBox{
        // width: calc(100%/3);

        label{
            display: inline-flex;
            align-items: center;

            input{
                width: 20px;
                height: 20px;
            }

            p{
                margin-bottom: 0;
                margin-left: 10px;
                font-size: 14px;
            }
            
        }
        .css-1jqq78o-placeholder {
            font-size: 13px;
        }
        .css-1dimb5e-singleValue {
            font-size: 13px;
        }
        &.with_button{
            display: flex;
            justify-content: end;

            button{
                width: 18%;
                padding: 10px;
            }
        }
    }

    @media only screen and (max-width: 768px){
        flex-wrap: wrap;

        .dashcheckBox{
            width: 100%;
            margin-bottom: 10px;

            &:last-child{
                margin-bottom: 0;

                button{
                    width: 100%;
                }
            }

            label{
                p{
                    font-size: 14px;
                }
            }  
        }
    }
}

.v_imagemainbox{
    margin-top: 23px;

    .v_imagebox{

        display: flex;
        justify-content: start;
        align-items: center;

        i{
            font-size: 18px;
            margin-left: 10px;
        }
    }

    

    .dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 400px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 32px rgba(0,0,0,.11);
        border: 1px solid #dadce0;
        border-radius: 8px;
    
        button{
            width: 25px;
            height: 25px;
            border: 1px solid #dadce0;
            color: #000;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 25px;
        }
    }
}


.dashboard__right{
    select{
        padding: 8px 20px 8px 4px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}
