.introjs-helperLayer {
    //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;

    //box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;

    &.helperlayerzoomed{
        transform: scale(1.3);
    }
  }

.introjs-tooltip{

    border-radius: 10px;

    .introjs-button{
        background-color: #0d6efd;
        color: #FFF;
        text-shadow: none;
        border-color: #0d6efd;
        font-family: "Montserrat", sans-serif !important;

        &.introjs-prevbutton.introjs-disabled{
            color: #9e9e9e;
            border-color: #bdbdbd;
            box-shadow: none;
            cursor: default;
            background-color: #f4f4f4;
            background-image: none;
            text-decoration: none;
        }

        &.introjs-prevbutton{
            color: #0d6efd;
            border-color: #0d6efd;
            box-shadow: none;
            cursor: default;
            background-color: transparent;
            background-image: none;
            text-decoration: none;
        }
    }



    .introjs-tooltip-header,.introjs-tooltiptext{
        color: #000;
        font-family: "Montserrat", sans-serif !important;

        .introjs-tooltip-title{
            font-family: "Montserrat", sans-serif !important;
            color: #0d6efd;
        }

        .introjs-skipbutton{
            font-family: "Montserrat", sans-serif !important;
            font-size: 48px;
            font-weight: 300;
            color: #000;

            &:hover{
                color:#0d6efd;
            }
        }
    }

    
}