$mobileBig: 767px;
$mobile: 480px;

.goalsmainBox{
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .goalsBox{
        width: calc(100%/6 - 0.5rem);
        margin-right: 0.5rem;
        padding: 1.5rem 1rem 4.5rem;
        border-radius: 1rem;
        border: 1px solid #dadce0;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        align-self: stretch;
        color: #000;
        position: relative;
        margin-bottom: 0.75rem;
        background-color: #F2F3F4;

        &:nth-child(6n){
            margin-right: 0;
        }

        &:before{
            content: '';
            background: url(../Image/goals.png);
            width: 60px;
            height: 60px;
            display: inline-block;
            background-size: contain;
            position: absolute;
            right: 15px;
            bottom: 10px;
            opacity: 0.5;
        }

        h4{
            margin-bottom: 0.75rem;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 0.8rem;
            line-height: 1.4;
            color: #0d6efd;
        }

        @media only screen and (max-width: $mobileBig){
            width: 100%;
            margin-right: 0;
            padding: 1.5rem 1rem;

            &:before{
                content: none;
            }
        }

        .goalscampaignmainBox{
            // height: 130px;
            max-height: 130px;
            overflow-y: auto;

            @media only screen and (max-width: $mobileBig){
                height: 80px;
                max-height: 80px;
            }

            .goalscampaignBox{
                width: 100%;
                margin-bottom: 0.5rem;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;         
                
                a{
                    color: #000;
                    display: flex;
                    background-color: #B9D9EB;
                    padding: 0.25rem 0.5rem;
                    font-size: 0.65rem;
                    border-radius: 0.25rem;
                    text-transform: capitalize;
                    // text-decoration: underline !important;

                    &.no_goals{
                        color: #000 !important;
                    }
                }

                &.nogoalscampaignBox{
                    display: flex;
                    align-items: center;

                    span{font-size: 0.65rem;margin-left: 0.25rem;}

                }

                &:last-child{
                    margin-bottom: 0;
                }
                
            }
        }
    }

    .goalscampaignBox{

        p{

            &.no_goals{
                color: #000;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
    }
}


.analyticalReportChartAnalysisOne__heading {
    display: flex;
    flex-direction: column;
    margin: 0 0 1rem;
    width: 100%;

    h2 {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2;
        color: #000000;
        letter-spacing: 1px;
        margin: 4px;
    }
}